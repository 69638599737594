import { DBSchenkerDefaultValues } from './DBSchenkerDefaultValues';

export const DBSchenker = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'dbSchenker',
  integration: 'DB_SCHENKER',
  additionalServices: {
    services: [],
  },
  login: '',
  clientNumber: '',
  url: '',
  product: '',
  labelReferenceType: '',
  labelType: '',
  defaultValues: DBSchenkerDefaultValues,
};
