<template>
  <div>
    <form-section
      :title="$t('courierSet.setData')"
    >
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSettings.labelType"
            :title=" $t('courierSet.labelFormatType')"
            rules="required"
            :filed-items="labelTypes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSettings.labelReferenceType"
            :title=" $t('courierSet.labelType')"
            rules="required"
            :filed-items="labelReferenceTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import SelectField from '@/components/inputs/SelectField.vue';
import FormSection from '@/components/shared/FormSection.vue';

export default Vue.extend({
  components: {
    SelectField, FormSection,
  },
  props: {
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    labelReferenceTypes: [],
    labelTypes: [],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getLabelSettings() {
      return this.getConfigurationSet();
    },
  },
});
</script>
