<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />

      <LabelSettings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import Vue from 'vue';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import { DBSchenker } from '@/constants/Speditors/DBSchenker/configuration/DBSchenker.js';
import ServiceAuthorization from '@/views/Speditors/DBSchenker/Forms/ServiceAuthorization.vue';
import LabelSettings from '@/views/Speditors/DBSchenker/Forms/LabelSettings.vue';

export default Vue.extend({
  name: 'DBSchenker',
  components: {
    ConfigurationSet, ServiceAuthorization, LabelSettings,
  },
  mixins: [configurationMixin],
  data: () => ({
    isNew: true,
    speditor: 'DBSchenker',
    organizationId: null,
    currentComponent: 'ServiceAuthorization',
    components: [{ code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'LabelSettings', name: 'courierSet.labelSettings' }],
  }),
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: DBSchenker });
    },
  },

});
</script>
